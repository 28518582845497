import React, { useEffect, useState } from "react";

const NotFoundPage = () => {
  const [isMount, setMount] = useState(false);

  useEffect(() => {
    setMount(true);
  }, []);

  // If a site is not fully rendered yet, prevent showing 404 error.
  // Prevents briefly showing 404 before client-only router is mounted,
  // when accessing client-only routes by URL or refreshing them.
  if (!isMount) {
    // note: for reasons not fully understood, adding a Loader here (or even material-ui's CircularProgress)
    // causes an unexpected behavior, which breaks the deployed production build for client-only pages.
    return <div>Please wait...</div>;
  }

  return (
    <div>
      <h1>404: Not Found</h1>
      <p>You just hit a route that doesn&#39;t exist... the sadness.</p>
    </div>
  );
};

export default NotFoundPage;
